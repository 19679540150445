<template>
  <div class="content">
    <ApplicationNannyInputPhotoMain
      v-model:imgCroppedSrc="applicationNanny.details.photoMain.src"
      @update:imgCroppedFile="applicationNanny.details.photoMain.file = $event"
      v-model:imgInputSrc="applicationNanny.details.photoMain.originalSrc"
      @update:imgInputFile="
        applicationNanny.details.photoMain.originalFile = $event
      "
      v-model:imgInputType="applicationNanny.details.photoMain.originalMimetype"
      v-model:imgInputName="applicationNanny.details.photoMain.originalFilename"
      @update:is-edit="isEdit = $event"
    />

    <ButtonBasicNext
      v-show="!isEdit"
      :to="{ name: ROUTE_APPLICATION_NANNY_CREATE_PHOTO_GALLERY_NAME }"
    />
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ApplicationNannyInputPhotoMain from "@/components/Application/Nanny/ApplicationNannyInputPhotoMain.vue";
import ButtonBasicNext from "@/components/UI/ButtonBasicNext.vue";
import {
  ROUTE_APPLICATION_NANNY_CREATE_NAME,
  ROUTE_APPLICATION_NANNY_CREATE_PHOTO_GALLERY_NAME,
} from "@/router/constants";
import { useRouter } from "vue-router";

export default {
  components: {
    ApplicationNannyInputPhotoMain,
    ButtonBasicNext,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();
    const router = useRouter();

    onMounted(() => {
      // GUARD -> redirect to the Start if no _id provided
      if (applicationNanny._id === null) {
        return router.push({ name: ROUTE_APPLICATION_NANNY_CREATE_NAME });
      }

      window.addEventListener("unload", () => handleUnload());
    });

    onUnmounted(() => {
      window.removeEventListener("unload", () => handleUnload());
    });

    watch(
      () => applicationNanny.details.photoMain.file,
      (photoMainCroppedFile) => {
        if (!photoMainCroppedFile) {
          return;
        }
        uploadPhotoMain();
        cachePhotoMainFromStore();
      }
    );

    const isEdit = ref(false);
    const photoMainCache = reactive({
      ...applicationNanny.details.photoMain,
    });

    const uploadPhotoMain = async () => {
      try {
        await applicationNanny.updatePhotoMain();
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };

    const cachePhotoMainFromStore = () => {
      Object.assign(photoMainCache, { ...applicationNanny.details.photoMain });
    };

    const resetPhotoMainInStore = () => {
      console.log("resetPhotoMainInStore");
      applicationNanny.details.photoMain.originalFile = null;
      applicationNanny.details.photoMain.file = null;
      applicationNanny.details.photoMain.originalFilename =
        photoMainCache.originalFilename;
      applicationNanny.details.photoMain.originalMimetype =
        photoMainCache.originalMimetype;
      applicationNanny.details.photoMain.originalSrc =
        photoMainCache.originalSrc;
    };

    const handleUnload = () => {
      alert("handleUnload");
      if (isEdit.value === true) {
        resetPhotoMainInStore();
      }
    };

    return {
      applicationNanny,
      isEdit,
      ROUTE_APPLICATION_NANNY_CREATE_PHOTO_GALLERY_NAME,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}

@media (max-width: 1200px) {
}
</style>
