<template>
  <div class="container">
    <BaseH1 :text="title" />

    <div class="wrapper container__wrapper">
      <AttachmentPhotoMain
        v-model:imgCroppedSrc="photoMain.imgCropped.src"
        @update:imgCroppedFile="photoMain.imgCropped.file = $event"
        v-model:imgInputSrc="photoMain.imgInput.src"
        @update:imgInputFile="photoMain.imgInput.file = $event"
        v-model:imgInputType="photoMain.imgInput.type"
        v-model:imgInputName="photoMain.imgInput.name"
        @update:is-edit="photoMain.isEdit = $event"
      />
      <TextHint
        :text="'Zdjęcie daje około 81 procent więcej telefonów dziennie!'"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import AttachmentPhotoMain from "@/components/AttachmentPhotoMain.vue";
import TextHint from "@/components/UI/TextHint.vue";

export default {
  components: {
    BaseH1,
    AttachmentPhotoMain,
    TextHint,
  },

  props: {
    imgCroppedSrc: String,
    imgInputSrc: String,
    imgInputType: String,
    imgInputName: String,
  },

  emits: [
    "update:imgCroppedSrc",
    "update:imgCroppedFile",
    "update:imgInputSrc",
    "update:imgInputFile",
    "update:imgInputType",
    "update:imgInputName",
    "update:isEdit",
  ],

  setup(props, { emit }) {
    const photoMain = reactive({
      imgInput: {
        src: props.imgInputSrc,
        file: null,
        type: props.imgInputType,
        name: props.imgInputName,
      },
      imgCropped: {
        src: props.imgCroppedSrc,
        file: null,
      },
      isEdit: false,
    });

    // 2-way binding
    watch(
      () => props.imgCroppedSrc,
      () => {
        photoMain.imgCropped.src = props.imgCroppedSrc;
      }
    );
    watch(
      () => photoMain.imgCropped.src,
      (newImgCroppedSrc) => {
        emit("update:imgCroppedSrc", newImgCroppedSrc);
      }
    );

    // Outgoing binding
    watch(
      () => photoMain.imgCropped.file,
      (newImgCroppedFile) => {
        emit("update:imgCroppedFile", newImgCroppedFile);
      }
    );

    // 2-way binding
    watch(
      () => props.imgInputSrc,
      () => {
        photoMain.imgInput.src = props.imgInputSrc;
      }
    );
    watch(
      () => photoMain.imgInput.src,
      (newImgInputSrc) => {
        emit("update:imgInputSrc", newImgInputSrc);
      }
    );

    // Outgoing binding
    watch(
      () => photoMain.imgInput.file,
      (newImgInputFile) => {
        emit("update:imgInputFile", newImgInputFile);
      }
    );

    // 2-way binding
    watch(
      () => props.imgInputType,
      () => {
        photoMain.imgInput.type = props.imgInputType;
      }
    );
    watch(
      () => photoMain.imgInput.type,
      (newImgInputType) => {
        emit("update:imgInputType", newImgInputType);
      }
    );

    // 2-way binding
    watch(
      () => props.imgInputName,
      () => {
        photoMain.imgInput.name = props.imgInputName;
      }
    );
    watch(
      () => photoMain.imgInput.name,
      (newImgInputNaimgInputName) => {
        emit("update:imgInputName", newImgInputNaimgInputName);
      }
    );

    // Outgoing binding
    watch(
      () => photoMain.isEdit,
      (newIsEdit) => {
        emit("update:isEdit", newIsEdit);
      }
    );

    const title = computed(() => {
      if (photoMain.isEdit) {
        return "Edytuj zdjęcie";
      }
      if (!photoMain.imgInput.src) {
        return "Dodaj zdjęcie";
      }
      return "Super! Prawie skończone!";
    });

    return {
      photoMain,
      title,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.container__wrapper {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;
}

@media (max-width: 1200px) {
}
</style>
